import Button from "@components/elements/button"
import Flex from "@components/elements/flex"
import Heading from "@components/elements/heading"
import Paragraph from "@components/elements/paragraph"
import React from "react"
import { useTheme } from "styled-components"
import PropTypes from "prop-types"
import List from "@components/elements/list"

export const SixtyDayPOC = ({ handleClick }) => {
  const { color } = useTheme()

  return (
    <Flex alignItems="flex-start" gap="1rem">
      <Heading as="h2" margin="4rem 0 1rem">
        60-Day Proof of Concept
      </Heading>
      <Paragraph>
        Accelerate the evaluation process, include all essential business
        requirements, and build a solid foundation for the application
        of&nbsp;Klipfolio within your organization. Our proven 60-day method
        includes:
      </Paragraph>
      <List gap="0" margin="0 0 1rem 1rem">
        <li>
          A thorough understanding of your current state and future goals.
        </li>
        <li>An in-depth data and infrastructure review.</li>
        <li>
          A plan that identifies, designs and implements a data stack and data
          tables that support your initiatives.
        </li>
        <li>Identification of key metrics.</li>
        <li>A customized set of metrics and metric dashboards.</li>
        <li>
          Onboarding for your business users as they explore and analyze
          metrics.
        </li>
      </List>
      <Button
        onClick={handleClick}
        notice={false}
        background={color.indigo600}
        arrow
      >
        Learn more
      </Button>
    </Flex>
  )
}

SixtyDayPOC.propTypes = {
  handleClick: PropTypes.func.isRequired,
}
