import Flex from "@components/elements/flex";
import styled from "styled-components";

export const IconWrapper = styled(Flex)`
  display: flex !important;
  aspect-ratio: 1 / 1;
  background-color: #fff;
  border-radius: 0.75rem;
  box-shadow: 0 0.5px 2.5px #00000029, 0 1px 8px #00000012;
  height: 40px;
  width: 40px;
  position: relative;
  padding: 10px;
`
