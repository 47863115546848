import React, { useCallback } from "react"
import Layout from "@components/layout"
import Flex from "@components/elements/flex"
import Div from "@components/elements/div"
import Modal from "@components/elements/modal"
import { Form } from "@components/common/form"
import HubspotForm from "react-hubspot-form"
import { FormWrapper } from "@components/styles/form-wrapper"
import { OnboardingTraining } from "@components/pages/services/sections/onboarding-training"
import { DedicatedDataHero } from "@components/pages/services/sections/dedicated-data-hero"
import { ImplementationServices } from "@components/pages/services/sections/implementation-services"
import { SixtyDayPOC } from "@components/pages/services/sections/60-day-poc"
import { HireAPartner } from "@components/pages/services/sections/hire-a-partner"
import { ServicesTestimonial } from "@components/pages/services/sections/services-testimonial"
import { ServicesHeader } from "@components/pages/services/sections/services-header"

const ServicesPage = () => {
  const [modalOpen, setModalOpen] = React.useState(false)

  const handleClick = useCallback(() => {
    setModalOpen(true)
  }, [setModalOpen])

  return (
    <Layout
      title="Klipfolio Services: Fast Onboarding & Implementation for Optimal Results"
      titleExtension=""
      description={`Save time and get great results with Klipfolio's expert services. Quick onboarding, team training, and customized implementation for superior business outcomes.`}
      marginless
      fullWidth
    >
      <Modal show={modalOpen} setModalState={setModalOpen}>
        <FormWrapper>
          <Form>
            <HubspotForm
              portalId="7477725"
              formId="344ede1a-eca1-4a2b-b1f0-9b4734bffcb0"
            />
          </Form>
        </FormWrapper>
      </Modal>
      <ServicesHeader />
      <Div textContainer margin="0 auto 10rem">
        <ServicesTestimonial />
        <Flex gap="4rem">
          <SixtyDayPOC handleClick={handleClick} />
          <OnboardingTraining handleClick={handleClick} />
          <DedicatedDataHero handleClick={handleClick} />
          <ImplementationServices handleClick={handleClick} />
        </Flex>
      </Div>
      <HireAPartner handleClick={handleClick} />
    </Layout>
  )
}

export default ServicesPage
